import type { AxiosResponse } from "axios";
import axios from "axios";
import Config from "./model/config";

// Types
export type Country = "se" | "fi";
export type Env = "ddev" | "dev" | "prod" | "preprod";
type EnvCfg = Record<Country, Record<Env, string>>;
export type JsonCfg = {
  country: Country;
  environment: Env;
  backend?: string;
};

// Environments
export const envs: EnvCfg = {
  se: {
    ddev: "http://localhost:8181",
    dev: "http://10.10.10.25:8081",
    preprod: "https://test.tc-webb.sll.se",
    prod: "https://tcweb.veripalvelu.fi",
  },
  fi: {
    ddev: "http://localhost:8181",
    dev: "http://10.10.10.25:8081",
    preprod: "https://test.tc-webb.sll.se",
    prod: "https://tcweb.veripalvelu.fi",
  },
};

// Config url
const cfgUrl = "/config.json";
let loading: Promise<AxiosResponse>;
// Default configuration
let cfg: JsonCfg = {
  country: "se",
  environment: "ddev",
};

export async function getConfig(): Promise<Config> {
  if (!loading) loading = axios.get(cfgUrl);
  try {
    cfg = (await loading).data as JsonCfg;
    if (typeof cfg !== "object") throw "Invalid data";
  } catch (err) {
    console.error(err);
    console.warn("Cannot parse config file");
  }
  // Return configuration
  return {
    country: cfg.country,
    url: cfg.backend || envs[cfg.country][cfg.environment],
    secret: process.env.VUE_APP_SECRET === "true", // TODO Not used atm. But could be useful again, somewhere in the future.
  } as Config;
}
